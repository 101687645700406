import { Delete, Edit, FilePresentOutlined } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { FileWithId } from './UploadPage';

interface Props {
	files: FileWithId[];
	setFiles: React.Dispatch<React.SetStateAction<FileWithId[]>>;
	removeFile: (id: string) => void;
	upload: () => void;
	uploading: boolean;
}

const formatBytes = (bytes: number, decimals = 2) => {
	if (!+bytes) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const UploadedFilesTable: FC<Props> = ({
	files,
	setFiles,
	removeFile,
	upload,
	uploading,
}) => {
	const [editFile, setEditFile] = useState<FileWithId>();

	return (
		<Stack
			direction="column"
			height="100%"
			alignItems="start"
			justifyContent="start"
			spacing="20px"
			paddingX="30px"
			paddingTop="30px"
		>
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography fontWeight="bold" fontSize="20px">
					{files.length} {files.length > 1 ? 'Videos' : 'Video'} Uploaded
				</Typography>
				<Button
					variant="contained"
					size="small"
					style={{ textTransform: 'none' }}
					onClick={() => upload()}
					disabled={uploading}
				>
					Upload
				</Button>
			</Stack>
			<Grid container rowSpacing={0.5}>
				{files.map(f => (
					<Grid key={f.id} item xs={6}>
						<Paper
							elevation={0}
							style={{
								textAlign: 'center',
								padding: '15px',
								margin: '2px',
								border: '1px solid',
							}}
						>
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Stack direction="row" alignItems="center" spacing={1}>
									<FilePresentOutlined />
									<Stack direction="column" justifyContent="center" alignItems="start">
										<Typography>{f.file.name}</Typography>
										<Typography fontSize="12px" color="grey">
											{formatBytes(f.file.size)}
										</Typography>
									</Stack>
									<Stack
										direction="column"
										justifyContent="center"
										alignItems="start"
										paddingLeft="20px"
									>
										<Tooltip title={f.title}>
											<Typography
												fontSize="15px"
												// color={f.title ? 'grey' : 'red'}
												maxWidth="250px"
												sx={{
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{f.title ?? 'Video'}
											</Typography>
										</Tooltip>
										<Tooltip title={f.description}>
											<Typography
												fontSize="15px"
												// color={f.description ? 'grey' : 'orange'}
												maxWidth="250px"
												sx={{
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{f.description ?? '#fyp'}
											</Typography>
										</Tooltip>
									</Stack>
								</Stack>
								<Stack direction="row" alignItems="center" spacing={1}>
									<IconButton
										children={<Edit />}
										disabled={uploading}
										onClick={() => setEditFile(f)}
									/>
									<IconButton
										children={<Delete />}
										color="error"
										onClick={() => removeFile(f.id)}
										disabled={uploading}
									/>
								</Stack>
							</Stack>
						</Paper>
					</Grid>
				))}
			</Grid>
			<Dialog open={!!editFile} onClose={() => setEditFile(undefined)}>
				<DialogTitle>
					<Typography fontSize="20px" color="black">
						Set video attributes
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={2}
						paddingTop="5px"
					>
						<TextField
							label="Title"
							value={editFile?.title}
							onChange={e => setEditFile({ ...editFile!, title: e.target.value })}
						/>
						<TextField
							label="Description"
							value={editFile?.description}
							onChange={e => setEditFile({ ...editFile!, description: e.target.value })}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						style={{ textTransform: 'none' }}
						onClick={() => {
							setFiles(files.map(f => (f.id === editFile?.id ? editFile : f)));
							setEditFile(undefined);
						}}
					>
						Done
					</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};
