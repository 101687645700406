import { CloudUpload } from '@mui/icons-material';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Stack,
	Typography,
} from '@mui/material';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';
import { UploadedFilesTable } from './UploadedFilesTable';
import { v4 as uuidv4 } from 'uuid';
import { ErrorDialog } from '../../Helpers/ErrorDialog';
import CreatableSelect from 'react-select/creatable';
import type { Option } from 'react-multi-select-component';
import apiClient from '../../ApiClient';
import axios from 'axios';
// import { mediaInfoFactory } from "mediainfo.js";

export type FileWithId = {
	id: string;
	title: string | null;
	description: string | null;
	file: File;
};

interface Groups {
	groups: string[];
}

const UploadPage = () => {
	const [error, setError] = useState<string | null>(null);
	const [errorTitle, setErrorTitle] = useState<string | null>(null);
	const [uploading, setUplaoding] = useState<boolean>(false);
	const [files, setFiles] = useState<FileWithId[]>([]);
	const [groups, setGroups] = useState<Option[]>([]);
	const [addedGroups, setAddedGroups] = useState<Option[]>([]);
	const [selectedGroup, setSelectedGroup] = useState<Option | null>(null);
	const [uploadDialog, setUploadDialog] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		getVideoGroups();
	}, []);

	// const readChunk = async (file: File, size: number, offset: number) => {
	// 	const buffer = await file.slice(offset, offset + size).arrayBuffer();
	// 	return new Uint8Array(buffer);
	// };

	// const getVideoInfo = async (file: File) => {
	// 	const mediainfo = await mediaInfoFactory();
	// 	const res = await mediainfo.analyzeData(file.size, (size, offset) =>
	// 		readChunk(file, size, offset),
	// 	);
	// 	console.log(res);
	// };

	const getVideoGroups = async () => {
		await apiClient
			.get<Groups>('core/groups/')
			.then(response => {
				const groupsResponse = response.data;
				setGroups(groupsResponse.groups.map(g => ({ value: g, label: g })));
			})
			.catch(err => {
				setErrorTitle('Failed to fetch video groups');
				if (axios.isAxiosError(err)) {
					const errorMessage =
						err.response?.data?.message || 'An error occurred while fetching video groups.';
					setError(errorMessage);
				} else {
					setError('An unexpected error occurred while fetching video groups.');
				}
			});
	};

	const uploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			setFiles(
				Array.from(files).concat(
					Array.from(event.target.files).map(f => ({
						id: uuidv4(),
						file: f,
						title: 'Video',
						description: '#fyp',
					}))
				)
			);
		}
	};

	const removeFile = (id: string) => {
		setFiles(files.filter(f => f.id !== id));
	};

	const uploadVids = async () => {
		setUplaoding(true);
		setUploadDialog(false);
		// biome-ignore lint/complexity/noForEach: <explanation>
		files.forEach(async f => {
			const formData = new FormData();
			formData.append('video', f.file);
			formData.append('title', f.title ?? '');
			formData.append('description', f.description ?? '');
			formData.append('group', selectedGroup?.value);
			await apiClient
				.post('core/videos/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.catch(error => {
					setErrorTitle('Failed to upload videos');
					if (axios.isAxiosError(error)) {
						const errorMessage =
							error.response?.data?.message || 'An error occurred while uploading videos.';
						setError(errorMessage);
					} else {
						setError('An unexpected error occurred while uploading videos.');
					}
				});
		});
		setFiles([]);
		setUplaoding(false);
	};

	const completeUpload = () => {
		if (!files || files.length === 0) {
			setErrorTitle('Failed to upload');
			setError('No file selected');
			return;
		}
		if (files.some(f => !f.title)) {
			setErrorTitle('Failed to upload');
			setError('Videos must be uploaded with a title');
			return;
		}
		setUploadDialog(true);
	};

	const handleAddGroup = (inputValue: string) => {
		setAddedGroups([...addedGroups, { value: inputValue, label: inputValue }]);
		setSelectedGroup({ value: inputValue, label: inputValue });
	};

	if (uploading)
		return (
			<Stack
				direction="column"
				flex={1}
				divider={<Divider orientation="horizontal" flexItem />}
				justifyContent="center"
				alignItems="center"
			>
				<CircularProgress />
			</Stack>
		);

	return (
		<>
			<Stack
				direction="column"
				flex={1}
				paddingBottom="40px"
				divider={<Divider orientation="horizontal" flexItem />}
			>
				<Stack direction="row" justifyContent="space-between" paddingX="20px" paddingY="20px">
					<Typography fontSize="25px" fontWeight="bold">
						Upload Videos
					</Typography>
					<Stack direction="row" spacing="5px">
						<Button
							variant="contained"
							style={{
								textTransform: 'none',
							}}
							onClick={completeUpload}
							disabled={files.length === 0 || uploading}
						>
							Complete Upload
						</Button>
					</Stack>
				</Stack>
				{files.length === 0 ? (
					<Stack
						direction="column"
						height="100%"
						alignItems="center"
						justifyContent="center"
						spacing="5px"
					>
						<Typography fontSize="15px" fontWeight="bold">
							Start by uploading a video
						</Typography>
						<Typography fontSize="12px" color="gray" paddingBottom="10px">
							Start scheduling new posts by uploading the video files here!
						</Typography>
						<Typography fontSize="12px" color="gray" paddingBottom="10px">
							Accepted formats: MP4, MOV
						</Typography>
						<Button
							variant="contained"
							startIcon={<CloudUpload />}
							onClick={() => fileInputRef.current?.click()}
						>
							Upload
						</Button>
					</Stack>
				) : (
					<UploadedFilesTable
						files={files}
						setFiles={setFiles}
						removeFile={removeFile}
						upload={() => fileInputRef.current?.click()}
						uploading={uploading}
					/>
				)}
			</Stack>
			<input
				ref={fileInputRef}
				style={{ display: 'none' }}
				type="file"
				multiple
				onChange={e => uploadFiles(e)}
			/>
			<ErrorDialog
				title={errorTitle}
				error={error}
				onClose={() => {
					setError(null);
					setErrorTitle(null);
				}}
			/>
			<Dialog open={uploadDialog} onClose={() => setUploadDialog(false)}>
				<DialogTitle>
					<Typography fontSize="20px" color="black">
						Assign video to group
					</Typography>
				</DialogTitle>
				<DialogContent>
					<CreatableSelect
						menuPortalTarget={document.body}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 9999 }),
						}}
						value={selectedGroup}
						options={groups.concat(addedGroups)}
						onCreateOption={handleAddGroup}
						onChange={(v, _) => setSelectedGroup(v)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={uploadVids}
						disabled={!selectedGroup}
						variant="contained"
						style={{ textTransform: 'none' }}
					>
						Done
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UploadPage;
