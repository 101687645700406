import DeleteIcon from '@mui/icons-material/Delete';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import apiClient from '../../ApiClient';
import type { TiktokAccount, Video } from '../Schedule/SchedulePage';

type Post = {
	id: number;
	video: number;
	video_title?: string;
	tiktok_account: number;
	tiktok_account_name?: string;
	task_id: string;
	user: string;
	status: string;
};

export default function StatusPage() {
	const [posts, setPosts] = useState<Post[] | []>([]);
	const [videos, setVideos] = useState<Video[] | []>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [postToDelete, setPostToDelete] = useState<number | null>(null);
	const [tiktokAccounts, setTikTokAccounts] = useState<TiktokAccount[] | []>([]);
	const fetchPosts = async () => {
		try {
			const res = await apiClient.get('core/posts/');
			const posts = res.data as Post[];
			setPosts(posts);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchVideos = async () => {
		try {
			const res = await apiClient.get('core/videos/');
			const videos = res.data as Video[];
			setVideos(videos);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchTikTokAccounts = async () => {
		try {
			const res = await apiClient.get('core/tiktokaccounts/');
			const tiktokAccounts = res.data as TiktokAccount[];
			setTikTokAccounts(tiktokAccounts);
		} catch (err) {
			console.error(err);
		}
	};

	const deletePosts = async (id: number) => {
		try {
			await apiClient.delete(`core/posts/${id}/`);
			await fetchPosts();
		} catch (err) {
			console.error(err);
		}
	};

	const handleDeleteClick = (postId: number) => {
		setPostToDelete(postId);
		setOpenDialog(true);
	};

	const handleConfirmDelete = (): void => {
		if (postToDelete) {
			deletePosts(postToDelete);
		}
		setOpenDialog(false);
		setPostToDelete(null);
	};

	const handleCancelDelete = (): void => {
		setOpenDialog(false);
		setPostToDelete(null);
	};

	useEffect(() => {
		fetchPosts();
		fetchVideos();
		fetchTikTokAccounts();
	}, []);

	useEffect(() => {
		if (!videos || !posts) {
			return;
		}
		setPosts(prev =>
			prev.map(e => {
				const videoMatch = videos.find(v => v.id === e.video);
				return {
					...e,
					video_title: videoMatch?.title || 'Video not found',
					tiktok_account_name: tiktokAccounts.find(t => t.id === e.tiktok_account)?.username,
				};
			})
		);
	}, [videos, tiktokAccounts]);

	return (
		<Stack direction="column" flex={1} divider={<Divider orientation="horizontal" flexItem />}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				paddingX="20px"
				paddingY="20px"
			>
				<Typography fontSize="25px" fontWeight="bold">
					Post Status
				</Typography>
			</Stack>

			<Stack
				direction="column"
				height="100%"
				alignItems="center"
				justifyContent="start"
				spacing="20px"
				paddingX="30px"
				paddingTop="30px"
			>
				<TableContainer>
					<Table
						style={{
							border: '1px solid rgb(0,0,0,0.1)',
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell>
									<strong>Video</strong>
								</TableCell>
								<TableCell>
									<strong>TikTok Account</strong>
								</TableCell>
								<TableCell>
									<strong>Status</strong>
								</TableCell>
								<TableCell>
									<strong>Actions</strong>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{posts.map(post => (
								<TableRow key={post.id}>
									<TableCell>{post.video_title}</TableCell>
									<TableCell>@{post.tiktok_account_name}</TableCell>
									<TableCell>
										<span
											className={`px-2 py-1 rounded-full text-sm ${
												post.status === 'completed'
													? 'bg-green-100 text-green-800'
													: post.status === 'failed'
													? 'bg-red-100 text-red-800'
													: 'bg-blue-100 text-blue-800'
											}`}
										>
											{post.status}
										</span>
									</TableCell>
									<TableCell>
										<IconButton
											onClick={() => handleDeleteClick(post.id)}
											size="small"
											color="error"
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>

			<Dialog open={openDialog} onClose={handleCancelDelete}>
				<DialogTitle>Delete Post</DialogTitle>
				<DialogContent>Are you sure you want to delete this post?</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelDelete}>Cancel</Button>
					<Button onClick={handleConfirmDelete} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
}
