import { Stack, Divider, Typography, Button } from '@mui/material';
import { useAuth } from '../../Contexts/AuthContext';
import { useCallback, useEffect, useState } from 'react';
import apiClient from '../../ApiClient';
import axios from 'axios';
import TikTokAccountList from './TiktokAccountList';
import Notification from '../NewSchedule/Notification';

export interface TiktokAccounts {
	id: string;
	username: string;
	code: string;
	access_token: string;
	refresh_token: string;
	refresh_expires: string;
	user: number;
}

export const SettingsPage = () => {
	const { username, email } = useAuth();
	const [tiktokAccounts, setTiktokAccounts] = useState<TiktokAccounts[] | null>(null);
	const [notification, setNotification] = useState<{
		message: string;
		severity: 'error' | 'success' | 'info' | 'warning';
	} | null>(null);

	const fetchAccounts = useCallback(async (): Promise<void> => {
		try {
			const res = await apiClient.get('/core/tiktokaccounts/');
			const tiktokAccounts = res.data as TiktokAccounts[];
			setTiktokAccounts(tiktokAccounts);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errorMessage =
					err.response?.data?.message || 'An error occurred while fetching TikTok accounts.';
				setNotification({ message: errorMessage, severity: 'error' });
			}
		}
	}, []);

	useEffect(() => {
		fetchAccounts();
	}, [fetchAccounts]);

	const handleAddTiktokAccount = async () => {
		try {
			const accessToken = localStorage.getItem('accessToken');
			window.location.href = `https://api.zonevid.com/api/auth/tiktok/oauth/?access=${accessToken}`;
		} catch (error) {
			console.error('Error redirecting to TikTok Oauth:', error);
			setNotification({
				message: 'Failed to redirect to Tiktok OAuth. Please try again later.',
				severity: 'error',
			});
		}
	};

	return (
		<Stack direction="column" flex={1} divider={<Divider orientation="horizontal" flexItem />}>
			{notification && (
				<Notification
					message={notification.message}
					severity={notification.severity}
					onClose={() => setNotification(null)}
					open={true}
				/>
			)}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				paddingX="20px"
				paddingY="20px"
			>
				<Typography fontSize="25px" fontWeight="bold">
					Settings
				</Typography>
			</Stack>
			<Stack direction="column" alignItems="start" spacing="20px" paddingX="30px" paddingY="30px">
				<Typography fontSize="20px" fontWeight="bold">
					Profile
				</Typography>
				<Stack
					direction="column"
					alignItems="start"
					justifyContent="center"
					spacing="5px"
					paddingLeft="20px"
				>
					<Stack direction="row" width="100%" spacing={2} alignItems="center">
						<Typography fontSize="15px" fontWeight="bold">
							User Name
						</Typography>
						<Typography color="grey">{username}</Typography>
					</Stack>
					<Stack direction="row" width="100%" spacing={2} alignItems="center">
						<Typography fontSize="15px" fontWeight="bold">
							Email
						</Typography>
						<Typography color="grey">{email}</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				justifyContent="start"
				spacing="20px"
				paddingX="30px"
				paddingY="30px"
			>
				<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
					<div className="flex flex-col gap-2">
						<Typography fontSize="20px" fontWeight="bold">
							Linked Tiktok Accounts
						</Typography>
						<TikTokAccountList tiktokAccounts={tiktokAccounts} onAccountRemoved={fetchAccounts} />
					</div>
					<Button
						variant="contained"
						style={{
							textTransform: 'none',
						}}
						onClick={handleAddTiktokAccount}
					>
						Add Account
					</Button>
				</Stack>
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				justifyContent="start"
				spacing="10px"
				paddingX="30px"
				paddingTop="30px"
			>
				<Typography fontSize="20px" fontWeight="bold" color="darkred">
					Delete Account
				</Typography>
				<Stack direction="column" alignItems="start" justifyContent="center" spacing="10px">
					<Typography>
						Once you delete your account, there is no going back. Please be certain.
					</Typography>
					<Button
						variant="contained"
						style={{
							paddingLeft: '20px',
							paddingRight: '20px',
							backgroundColor: 'red',
							textTransform: 'none',
						}}
					>
						Delete account
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
