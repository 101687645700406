import type React from 'react';
import {
	createContext,
	useContext,
	useState,
	useEffect,
	type ReactNode,
	useCallback,
} from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import apiClient from '../ApiClient';

interface AuthContextType {
	login: (
		email: string,
		password: string
	) => Promise<string | null>;
	signup: (
		email: string,
		username: string,
		password1: string,
		password2: string
	) => Promise<string | null>;
	forgotPassword: (email: string) => Promise<string | null>;
	logout: () => void;
	username: string;
	email: string;
	isAuthenticated: boolean;
	accessToken: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [accessToken, setAccessToken] = useState<
		string | null
	>(localStorage.getItem('accessToken'));
	const [refreshToken, setRefreshToken] = useState<
		string | null
	>(localStorage.getItem('refreshToken'));

	const [username, setUsername] = useState<string>('');
	const [email, setEmail] = useState<string>('');

	const saveTokens = useCallback(
		(
			accessToken: string,
			refreshToken: string
		) => {
			setAccessToken(
				accessToken
			);
			setRefreshToken(
				refreshToken
			);
			localStorage.setItem(
				'accessToken',
				accessToken
			);
			localStorage.setItem(
				'refreshToken',
				refreshToken
			);
		},
		[]
	);

	const removeTokens = useCallback(() => {
		setAccessToken(null);
		setRefreshToken(null);
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
	}, []);

	const login = async (email: string, password: string) => {
		try {
			const response =
				await fetch(
					`${process.env.REACT_APP_API_URL}/auth/login/`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(
							{
								email: email,
								password: password,
							}
						),
					}
				);

			const data =
				await response.json();

			if (response.ok) {
				saveTokens(
					data.access,
					data.refresh
				);
				return null;
			}
			return Object.keys(
				data
			)
				.map(
					key =>
						`${key}: ${data[
							key
						].join(
							' '
						)}`
				)
				.join(
					'\n'
				);
		} catch (err) {
			removeTokens();
			return 'An unexpected server error occured';
		}
	};

	const signup = async (
		email: string,
		username: string,
		password1: string,
		password2: string
	) => {
		try {
			const response =
				await fetch(
					`${process.env.REACT_APP_API_URL}/auth/registration/`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(
							{
								email: email,
								username: username,
								password1: password1,
								password2: password2,
							}
						),
					}
				);
			if (response.ok) {
				return null;
			}
			const data =
				await response.json();
			return Object.keys(
				data
			)
				.map(
					key =>
						`${key}: ${data[
							key
						].join(
							' '
						)}`
				)
				.join(
					'\n'
				);
		} catch (err) {
			removeTokens();
			return 'An unexpected server error occured';
		}
	};

	const forgotPassword = async (email: string) => {
		try {
			const response =
				await fetch(
					`${process.env.REACT_APP_API_URL}/auth/password/reset/`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(
							{
								email: email,
							}
						),
					}
				);

			const data =
				await response.json();

			if (response.ok) {
				return null;
			}
			return Object.keys(
				data
			)
				.map(
					key =>
						`${key}: ${data[
							key
						].join(
							' '
						)}`
				)
				.join(
					'\n'
				);
		} catch (err) {
			removeTokens();
			return 'An unexpected server error occured';
		}
	};

	const refreshAccessToken = useCallback(async () => {
		if (!refreshToken) return;

		try {
			const response =
				await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/token/refresh/`,
					{
						refresh: refreshToken,
					}
				);
			if (
				response.status >=
					200 &&
				response.status <
					300
			) {
				const data =
					response.data;
				saveTokens(
					data.access_token,
					data.refresh_token
				);
			}
		} catch (error) {
			console.error(
				'Refresh token failed:',
				error
			);
			removeTokens();
		}
	}, [refreshToken, saveTokens, removeTokens]);

	const getUserInfo = useCallback(async () => {
		try {
			const response =
				await apiClient.get(
					`${process.env.REACT_APP_API_URL}/auth/user/`
				);
			if (
				response.status >=
					200 &&
				response.status <
					300
			) {
				const data =
					response.data;
				setUsername(
					data.username
				);
				setEmail(
					data.email
				);
			}
		} catch (error) {
			console.error(
				'Failed To fetch user',
				error
			);
			setUsername('');
			setEmail('');
		}
	}, []);

	const logout = async () => {
		try {
			await apiClient.post(
				`${process.env.REACT_APP_API_URL}/auth/logout/`
			);
		} catch (error) {
			console.error(
				'Failed To logout',
				error
			);
		} finally {
			removeTokens();
		}
	};

	const checkTokenExpiration = useCallback(async () => {
		if (accessToken) {
			const { exp } =
				jwtDecode<{
					exp: number;
				}>(
					accessToken
				);
			if (
				Date.now() >=
				exp *
					1000
			) {
				await refreshAccessToken();
			}
		}
	}, [accessToken, refreshAccessToken]);

	useEffect(() => {
		checkTokenExpiration();
		getUserInfo();
		const intervalId = setInterval(
			checkTokenExpiration,
			60000
		); // Check every minute

		return () => clearInterval(intervalId);
	}, [accessToken, getUserInfo, checkTokenExpiration]);

	const value = {
		accessToken,
		login,
		signup,
		forgotPassword,
		logout,
		username,
		email,
		isAuthenticated: !!accessToken,
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error(
			'useAuth must be used within an AuthProvider'
		);
	}
	return context;
};

export { AuthProvider, useAuth };
