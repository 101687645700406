import { Stack, Typography } from '@mui/material';
import { AppRoutes } from '../ApplicationRoutes';

export const Footer = () => {
	return (
		<Stack paddingY="20px" direction="row" justifyContent="center" alignItems="center" spacing={2}>
			<Typography color="gray">©2025 Zone Media Services</Typography>
			<a href={AppRoutes.TermsOfService} style={{ color: 'gray', textDecoration: 'none' }}>
				Terms Of Service
			</a>
			<a href={AppRoutes.PrivacyPolicy} style={{ color: 'gray', textDecoration: 'none' }}>
				Privacy Policy
			</a>
		</Stack>
	);
};
