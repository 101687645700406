import {
	Button,
	Divider,
	MenuItem,
	Select,
	type SelectChangeEvent,
	Stack,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import type { Video } from '../Schedule/SchedulePage';
import apiClient from '../../ApiClient';
import { useScheduleContext } from '../../Contexts/ScheduleContext';
import { CalendarMonth } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import VideoPlayer from './VideoPlayer';

export default function NewSchedulePage() {
	const [groups, setGroups] = useState<string[]>([]);
	const [videos, setVideos] = useState<Video[]>([]);
	const { selectedGroup, setSelectedGroup } = useScheduleContext();
	const navigate = useNavigate();

	useEffect(() => {
		fetchGroups();
		fetchVideos();
	}, []);

	const fetchGroups = async () => {
		try {
			apiClient
				.get('/core/groups/')
				.then(res => res.data)
				.then(data => setGroups(data.groups))
				.catch(err => console.log(err));
		} catch (err) {
			console.error(err);
		}
	};

	const fetchVideos = async () => {
		try {
			apiClient
				.get('/core/videos/')
				.then(res => res.data)
				.then(data => setVideos(data));
		} catch (err) {
			console.error(err);
		}
	};

	const handleGroupChange = (event: SelectChangeEvent) => {
		setSelectedGroup(event.target.value as string);
	};

	return (
		<Stack direction={'column'} flex={1} divider={<Divider orientation="horizontal" flexItem />}>
			<Stack direction={'row'} justifyContent={'space-between'} paddingX={'20px'} paddingY={'20px'}>
				<Typography fontSize={'25px'} fontWeight={'bold'}>
					New Schedule (Will select an unposted video from chosen group to post at selected times)
				</Typography>
			</Stack>
			<Stack
				direction={'column'}
				gap={2}
				justifyContent={'space-between'}
				paddingX={'20px'}
				paddingY={'20px'}
			>
				<h1 className="font-semibold text-xl text-neutral-700">Select your video group</h1>
				<Select
					labelId="video-group-select-label"
					id="video-group-select"
					value={selectedGroup}
					onChange={handleGroupChange}
					sx={{ maxWidth: 300 }}
				>
					{groups.length > 0 ? (
						groups.map(group => (
							<MenuItem key={group} value={group}>
								{group}
							</MenuItem>
						))
					) : (
						<MenuItem disabled>No groups available</MenuItem>
					)}
				</Select>

				<div className="flex flex-col gap-8">
					{selectedGroup.length > 0 && (
						<div className="flex flex-col gap-2">
							<h1 className="font-semibold text-xl text-neutral-700">Videos in the group</h1>
							<div className="flex gap-8">
								{videos
									.filter(v => v.group === selectedGroup)
									.map(v => (
										<div className="flex flex-col-reverse gap-2" key={v.id}>
											<p>File name: {v.title}</p>
											<VideoPlayer videoUrl={v.video} />
										</div>
									))}
							</div>
						</div>
					)}
					<Button
						variant="outlined"
						sx={{ minWidth: 220, maxWidth: 230, fontWeight: 'bold' }}
						startIcon={<CalendarMonth />}
						disabled={selectedGroup.length === 0}
						onClick={() => navigate('/select-schedule')}
					>
						Next: Pick Schedule
					</Button>
				</div>
			</Stack>
		</Stack>
	);
}
